$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function(){
        $(this).toggleClass("active");
        $(".js-navmobile").toggleClass("active");
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * sliderMedia
	// *
	// *
	var $sliderMedia = $(".js-slidermedia");

	if ($sliderMedia.length){
		$sliderMedia.each(function(){
			$(this).slick({
				fade: false,
				dots: false,
				infinite: true,
				arrows: true,
				autoplay: true,
                autoplaySpeed: 3000,
				speed: 1000,
				prevArrow: $(this).siblings(".js-slidermedia-prev"),
				nextArrow: $(this).siblings(".js-slidermedia-next"),
			});
		});
	}



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * loadmore
    // *
    // *
    $(".js-loadmore-button").click(function(){
        $(this).hide().parents(".js-loadmore").find(".js-loadmore-item").show();
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter products
    // *
    // *
    if($(".js-filter-container").length) {
            
        var mixer = mixitup('.js-filter-container', {
            load: {
                filter: '.all-level-1.all-level-2'
            },
            selectors: {
                target: '.js-filter-element'
            },
            multifilter: {
                enable: true
            }
        });

        // set the state of your filter groups, to match your `load.filter` selector
        mixer.setFilterGroupSelectors('level-1', '.all-level-1');
        mixer.setFilterGroupSelectors('level-2', '.all-level-2');
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * modal
    // *
    // *
    $("[data-modal-button]").click(function(){
        var id = $(this).data("modal-button");
        window.location.hash = id;
        openModal($("#" + id));
    });

    // open modal if exists
    if(window.location.hash) {
        var hash = window.location.hash;
        if (hash && hash != '#collections' && $(hash).length && $(".js-modal").length) {
            openModal($(hash));
        }
    }

    // open modal
    function openModal($object) {
        $object.addClass("active");
        $("html, body").addClass("freeze");
    }

    // close popUp
    $(".js-modal-close").click(function(){
        window.location.hash = 'collections';
        $(".js-modal").removeClass("active");
        $("html, body").removeClass("freeze");
    });


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function(){
    if($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    }else {
        $(".js-header").removeClass("sticky");
    }
});



$(window).on("load resize", function(){

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * cluster 
    // *
    // *
    // set content padding for inverse sticky effect
    if($(window).width() > 600) {
        var $cluster = $(".js-cluster");

        if ($cluster.length) {
            $cluster.each(function () {
                var heightImage = $(this).find(".js-cluster-image").outerHeight(),
                    heightContent = $(this).find(".js-cluster-inner").outerHeight(),
                    newPaddingContent = heightImage - heightContent;
    
                    $(this).find(".js-cluster-content").css("padding-top", newPaddingContent);
                    $(this).find(".js-cluster-content").css("padding-bottom", newPaddingContent);
            });
        }
    };
});
